import { extendTheme } from "@chakra-ui/react";

export default extendTheme({
  fonts: {
    heading: "Inter",
    body: "Inter",
  },
  colors: {
    red: "#b20c13",
    light: "#e8bd9b",
    brown: "#742d13",
    brownLight: "#b07651",
    simple: "#c8b8a5",
    dark: "#393c28"
  }
});
