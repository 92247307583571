import { Flex, SimpleGrid, GridItem } from "@chakra-ui/react";
import GamePlayImg from "../assets/images/gameplay.png";
import MilkImg from "../assets/images/milk.svg";

export default function GamePlayInformation() {
    return (
        <Flex
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="initial"
            paddingBottom="10px"
            bgColor="brownLight"
            id="gameplay"
        >
            <SimpleGrid columns={[null, null, 6, 6]} width="100%" paddingY={[5,0]}>
                <GridItem colStart={[1,2]} colSpan={1} display="flex">
                <img src={MilkImg} alt="milkimg"/>
                </GridItem>
                <GridItem colSpan={1} display="flex" alignItems="center" color="white" fontWeight="700" paddingX={[5,0]}>
                    <ol>
                        <li>Obtén una granja NFT</li>
                        <li>Compra los materiales de producción</li>
                        <li>Elije la misión que te gustaría hacer</li>
                        <li>Agrega los materiales a la misión y ejecuta</li>
                        <li>Finaliza la misión y GANA</li>
                    </ol>
                </GridItem>
                <GridItem colSpan={2} marginTop={[5,0]}>
                    <img src={GamePlayImg} alt="gameplay"/>
                </GridItem>
            </SimpleGrid>
        </Flex>
    );
}
