import {
    Heading,
    Box,
    Center,
    Image,
    Text,
    Stack,
    Button,
    useColorModeValue,
} from '@chakra-ui/react';
import GranjaImg from "../assets/images/granja.jpg";

interface PropsCard {
    name?: string;
    price?: number;
    color?: string;
    data?: any;
}
export default function CardPresale(info: PropsCard) {

    const {
        name,
        price,
        color,
        data
    } = info;
    return (
        <Center py={6}>
            <Box
                maxW={'270px'}
                w={'full'}
                bg={useColorModeValue('white', 'gray.800')}
                boxShadow={'2xl'}
                rounded={'md'}
                overflow={'hidden'}
                border={`5px solid ${color || 'gray'}`}>
                <Image
                    h={'120px'}
                    w={'full'}
                    src={GranjaImg}
                    objectFit={'cover'}
                />
                {/* <Flex justify={'center'} mt={-12}>
            <Avatar
              size={'xl'}
              src={
                'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ'
              }
              alt={'Author'}
              css={{
                border: '2px solid white',
              }}
            />
          </Flex> */}

                <Box p={6}>
                    <Stack spacing={0} align={'center'} mb={5}>
                        <Heading fontSize={'2xl'} fontWeight={500} fontFamily={'body'}>
                            {name}
                        </Heading>
                        <Text color={'gray.500'}>{price} BNB</Text>
                    </Stack>

                    <Stack direction={'row'} justify={'center'} spacing={6}>
                        <Stack spacing={0} align={'center'}>
                            <Text fontWeight={600}>{data.max_animales}</Text>
                            <Text fontSize={'sm'} color={'gray.500'} textAlign="center">
                                Animales Máximo
                            </Text>
                        </Stack>
                        <Stack spacing={0} align={'center'}>
                            <Text fontWeight={600}>{data.max_parcelas}</Text>
                            <Text fontSize={'sm'} color={'gray.500'} textAlign="center">
                                Parcelas Máximo
                            </Text>
                        </Stack>
                    </Stack>
                    <Stack direction={'row'} justify={'center'} spacing={6} marginTop="3">
                        <Stack spacing={0} align={'center'}>
                            <Text fontWeight={600}>{data.random_animales}</Text>
                            <Text fontSize={'sm'} color={'gray.500'} textAlign="center">
                                Animales Random
                            </Text>
                        </Stack>
                        <Stack spacing={0} align={'center'}>
                            <Text fontWeight={600}>{data.random_parcelas}</Text>
                            <Text fontSize={'sm'} color={'gray.500'} textAlign="center">
                                Parcela Random
                            </Text>
                        </Stack>
                    </Stack>

                    <Button
                        w={'full'}
                        mt={8}
                        bg={useColorModeValue('#151f21', 'gray.900')}
                        color={'white'}
                        rounded={'md'}
                        _hover={{
                            transform: 'translateY(-2px)',
                            boxShadow: 'lg',
                        }}
                        disabled={true}>
                        Próximamente
                    </Button>
                </Box>
            </Box>
        </Center>
    );
}