import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import theme from "./theme";
import "@fontsource/inter";
import Home from "./pages/Home";
import Presale from "./pages/Presale";
import Footer from "./components/Footer";

function App() {

  return (
    <>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/presale" exact>
            <Presale />
          </Route>
          <Redirect to="/" />
          
        </Switch>
        </BrowserRouter>
        <Footer />
      </ChakraProvider>
    </>

  );
}

export default App;
