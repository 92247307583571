import { useDisclosure, Box, Grid, Button, Text, Link, Tooltip, IconButton, Flex } from "@chakra-ui/react";
import ConnectButton from "./ConnectButton";
import LogoSmall from "../assets/images/logosmall.png";
import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import { useState } from "react";

function Toolbar() {
    const { onOpen } = useDisclosure();
    const [display, changeDisplay] = useState('none')
    return (
        <>
            <Grid className="navbar" templateColumns='repeat(2, 1fr)' gap={2} display={['none', 'none', 'flex', 'flex']}>
                <Box w='100%'>
                    <img src={LogoSmall} width="170px" alt="logoimg" />
                </Box>
                <Box w='100%' h='10' justifyContent="end" display="flex">
                    <Text display="flex" alignItems="center" marginRight="15px">
                        <Link color='white' fontWeight="700" href='#gameplay'>
                            Gameplay
                        </Link>
                    </Text>
                    <Text display="flex" alignItems="center" marginRight="15px">
                        <Link color='white' fontWeight="700" href='#roadmap'>
                            Roadmap
                        </Link>
                    </Text>
                    <Text display="flex" alignItems="center" marginRight="15px">
                        <Link color='white' fontWeight="700" href='#economia'>
                            Economía
                        </Link>
                    </Text>
                    <Text display="flex" alignItems="center" marginRight="15px">
                        <Link color='white' fontWeight="700" href='https://el-rancho.gitbook.io/el-rancho/' isExternal>
                            Whitepaper
                        </Link>
                    </Text>
                    <Tooltip label="Oncoming" aria-label='A tooltip'>

                        <Button bgColor="dark" color="simple" marginRight='5'>
                            Play
                        </Button>
                    </Tooltip>
                    <ConnectButton handleOpenModal={onOpen} />
                </Box>
            </Grid>
            <Grid className="navbar" templateColumns='repeat(2, 1fr)' gap={2} display={['flex', 'flex', 'none', 'none']}>
                <Box w='100%'>
                    <img src={LogoSmall} width="170px" alt="logoimg" />
                </Box>
                <IconButton
                    aria-label="Open Menu"
                    size="lg"
                    mr={2}
                    icon={
                        <HamburgerIcon />
                    }
                    onClick={() => changeDisplay('flex')}
                    display={['flex', 'flex', 'none', 'none']}
                />
                <Flex
                    w='100vw'
                    display={display}
                    bgColor="dark"
                    zIndex={20}
                    h="100vh"
                    pos="fixed"
                    top="0"
                    left="0"
                    overflowY="auto"
                    flexDir="column"                    
                >
                    <Flex justify="flex-end">
                        <IconButton
                            mt={2}
                            mr={2}
                            aria-label="Open Menu"
                            size="lg"
                            icon={
                                <CloseIcon />
                            }
                            onClick={() => changeDisplay('none')}
                        />
                    </Flex>

                    <Flex
                        flexDir="column"
                        align="center"
                    >
                        <ConnectButton handleOpenModal={onOpen} />
                        <Text display="flex" alignItems="center" marginTop="15px">
                            <Link color='white' fontWeight="700" onClick={() => changeDisplay('none')} href='#gameplay'>
                                Gameplay
                            </Link>
                        </Text>
                        <Text display="flex" alignItems="center" marginTop="15px">
                            <Link color='white' fontWeight="700" onClick={() => changeDisplay('none')} href='#roadmap'>
                                Roadmap
                            </Link>
                        </Text>
                        <Text display="flex" alignItems="center" marginTop="15px">
                            <Link color='white' fontWeight="700" onClick={() => changeDisplay('none')} href='#economia'>
                                Economía
                            </Link>
                        </Text>
                        <Text display="flex" alignItems="center" marginTop="15px">
                            <Link color='white' fontWeight="700" onClick={() => changeDisplay('none')} href='https://el-rancho.gitbook.io/el-rancho/' isExternal>
                                Whitepaper
                            </Link>
                        </Text>
                        <Tooltip label="Oncoming" aria-label='A tooltip'>

                            <Button bgColor="red" color="simple" marginTop='5'>
                                Play
                            </Button>
                        </Tooltip>
                        
                    </Flex>
                </Flex>
            </Grid>
        </>

    );
}

export default Toolbar;
