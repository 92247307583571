import { Button, Grid, Center, GridItem, Link, Text, SimpleGrid, Box } from "@chakra-ui/react";
import Layout from "../components/Layout";
import "@fontsource/inter";
import Milker from "../assets/images/milker.png";
import Logo from "../assets/images/logo.png";
import TelegramIcon from "../assets/icons/Icons";
import ConnectWallet from "../components/Toolbar";
import GamePlayInformation from "../views/GamePlayInformation";
import Economia from "../views/Economia";
import { FaTelegram } from "react-icons/fa";

const Home = () => {
  return (
    <>
      <Layout className='principalHome'>
        <ConnectWallet />
        <Center width="100%" color='white'>
          <img src={Logo} width="400px" alt="logo" />
        </Center>
        <Center width="100%" color="white" display={['none','none','flex','flex']}>
          <img src={TelegramIcon} width="50" alt="telegram" /> 
          <Text fontWeight="900">
          Unete a nuestro telegram
          </Text>
        </Center>
        <Center width="100%" marginTop={[5,5,10,10]}>
          <Link href="https://t.me/elranchooficial" isExternal>
            <Button colorScheme='telegram' marginRight='10' leftIcon={<FaTelegram />}>
              Español
            </Button>
          </Link>
          <Link href="https://t.me/elranchoann" isExternal >
            <Button colorScheme='telegram' leftIcon={<FaTelegram />}>
              Anuncios
            </Button>
          </Link>
        </Center>
        <SimpleGrid columns={[null, null, 6, 6]}  marginTop={[5,5,10,10]}>
          <GridItem justifyContent="end" display={["flex", "grid"]} colStart={2} colSpan={1} textAlign={["left", "right"]} paddingRight="10px">
            <Text fontSize={[18, 20, 36, 36]} color="white" fontWeight="900">
              Sobre el
            </Text>
            <Text fontSize={[18, 20, 36, 36]} color="red" fontWeight="900">
              &nbsp;Juego
            </Text>
          </GridItem>
          <GridItem color='white' colSpan={3} textAlign="justify" fontSize={[12,14,16,16]} paddingX={[5,0]}>
            <span>EL RANCHO es un juego NFT Play to Earn en el que puedes construir tu granja y recibir recompensas por tu progreso.</span>
            <br />
            <br />
            <span>SIN CARGOS. El juego cuenta con infraestructura propia para la comodidad de los jugadores.</span>
          </GridItem>
        </SimpleGrid>
      </Layout>
      <Box className="puas" height="25"></Box>
      <GamePlayInformation />
      <Box className="cerca2" height="70"></Box>
      <Box className='principalHome2' paddingTop="10" paddingBottom="10" >
        <Grid templateColumns='repeat(6, 1fr)' gap={6} width="100%" id="roadmap" className="road">
          <GridItem justifyContent="end" display="flex" colSpan={2} colStart={3}>
            <div className="about-game">
              <span>roadmap</span></div>
          </GridItem>
        </Grid>
        <SimpleGrid minChildWidth='120px' paddingTop="10" paddingStart="20">
          <GridItem justifyContent="start" display="grid" colSpan={1} paddingTop="15px" height='200px'>
          <img src={Milker} width="200px" alt="logo" />
          </GridItem>
          <GridItem justifyContent="start" display="grid" colSpan={2} paddingTop="15px" height='200px' className="container-roadmap">
            <div className="oval"></div>
            <div className="title-month">Diciembre 2021</div>
            <div className="description-point">Creación del roadmap</div>
            <div className="description-point">Creación del whitepaper</div>
            <div className="description-point">Creación de redes sociales</div>
          </GridItem>
          <GridItem justifyContent="start" display="grid" colSpan={2} paddingTop="15px" height='200px' className="container-roadmap">
            <div className="oval"></div>
            <div className="title-month">Enero 2022</div>
            <div className="description-point">Preventa NFT</div>
            <div className="description-point">Beta cerrada del juego</div>
            <div className="description-point">Creación del contrato del Token</div>
          </GridItem>
          <GridItem justifyContent="start" display="grid" colSpan={2} paddingTop="15px" height='200px' className="container-roadmap">
            <div className="oval"></div>
            <div className="title-month">Febrero 2022</div>
            <div className="description-point">Preventa Token</div>
            <div className="description-point">Airdrop a holders de NFT</div>
            <div className="description-point">Beta abierta del juego</div>
          </GridItem>
          <GridItem justifyContent="start" display="grid" colSpan={2} paddingTop="15px" height='200px' className="container-roadmap">
            <div className="oval"></div>
            <div className="title-month">Marzo 2022</div>
            <div className="description-point">Lanzamiento del juego</div>
          </GridItem>
          <GridItem justifyContent="start" display="grid" colSpan={1} paddingTop="15px" height='1px'></GridItem>
        </SimpleGrid>
      </Box>
      <Box className="cerca" height="70"></Box>
      <Economia />
      <Box className="puas" height="25"></Box>
    </>
  );
}

export default Home;
