import { Flex, Link, GridItem, Text, ListItem, List, SimpleGrid } from "@chakra-ui/react";

export default function Footer() {
    return (
        <Flex
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="initial"
            className="principalHome"
            paddingBottom="10px"
        >
            <SimpleGrid columns={[2,2,8,8]} width="100%" paddingY="20px">
                <GridItem colStart={[null,null,2,2]} colSpan={2} marginBottom={[5,5,0,0]} display="flex" justifyContent="center">
                    <Text color="white" fontWeight="900" >
                        EL RANCHO
                    </Text>
                </GridItem>
                <GridItem colSpan={2} marginBottom={[5,5,0,0]} display="grid" alignItems="center" color="white" fontWeight="700" justifyContent="center">
                    <Text color="white" fontWeight="900" marginBottom="10px">
                        SOCIAL MEDIA
                    </Text>
                    <List spacing={3}>
                        <ListItem>
                            <Link href="https://twitter.com/ElRanchoNft" isExternal>
                                <Text color="white" fontWeight="500">
                                    Twitter @ElRanchoNft
                                </Text>
                            </Link>
                        </ListItem>
                        <ListItem>
                            <Link href="https://t.me/elranchooficial" isExternal>
                                <Text color="white" fontWeight="500">
                                    Telegram - Community
                                </Text>
                            </Link>
                        </ListItem>
                        <ListItem>
                            <Link href="https://t.me/elranchoann" isExternal>
                                <Text color="white" fontWeight="500">
                                    Telegram - Announcements
                                </Text>
                            </Link>
                        </ListItem>
                    </List>
                </GridItem>
                <GridItem colSpan={2} display="grid" justifyContent="center">
                    <Text color="white" fontWeight="900" marginBottom="10px">
                        INFORMATION
                    </Text>
                    <List spacing={3}>
                        <ListItem>
                            <Link href="https://el-rancho.gitbook.io/el-rancho/" isExternal>
                                <Text color="white" fontWeight="500">
                                    Whitepaper
                                </Text>
                            </Link>
                        </ListItem>
                    </List>
                </GridItem>
            </SimpleGrid>
            {/* <Grid templateColumns='repeat(6, 1fr)' gap={6} width="100%" paddingY="20px">
                <GridItem colStart={2} colSpan={1} display="flex">
                    <Text color="white" fontWeight="900">
                        EL RANCHO
                    </Text>
                </GridItem>
                <GridItem colSpan={2} display="grid" alignItems="center" color="white" fontWeight="700">
                    <Text color="white" fontWeight="900" marginBottom="10px">
                        SOCIAL MEDIA
                    </Text>
                    <List spacing={3}>
                        <ListItem>
                            <Link href="https://t.me/elranchooficial" isExternal>
                                <Text color="white" fontWeight="500">
                                    Telegram - Community
                                </Text>
                            </Link>
                        </ListItem>
                        <ListItem>
                            <Link href="https://t.me/elranchoann" isExternal>
                                <Text color="white" fontWeight="500">
                                    Telegram - Announcements
                                </Text>
                            </Link>
                        </ListItem>
                    </List>
                </GridItem>
                <GridItem colSpan={1}>
                    <Text color="white" fontWeight="900" marginBottom="10px">
                        INFORMATION
                    </Text>
                    <List spacing={3}>
                        <ListItem>
                            <Link href="https://el-rancho.gitbook.io/el-rancho/" isExternal>
                                <Text color="white" fontWeight="500">
                                    Whitepaper
                                </Text>
                            </Link>
                        </ListItem>
                    </List>
                </GridItem>
            </Grid> */}
        </Flex>
    );
}
