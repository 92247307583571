import { Flex, Grid, GridItem } from "@chakra-ui/react";
import TokenomicsImg from "../assets/images/tokenomics.png";

export default function Economia() {
    return (
        <Flex
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="initial"
            paddingBottom="10px"
            bgColor="brownLight"
            id="economia"
        >


            <Grid templateColumns='repeat(8, 1fr)' gap={8} width="100%">
            <GridItem colStart={2} colSpan={6} display="flex" justifyContent="center" paddingY="20px">
                <img src={TokenomicsImg} alt="tokenomicsimg"/>
                </GridItem>
                
            </Grid>
        </Flex>
    );
}
