import { Flex, Center, GridItem, SimpleGrid } from "@chakra-ui/react";
import "@fontsource/inter";
import Logo from "../assets/images/logo.png";
import ConnectWallet from "../components/Toolbar";
import CardPresale from "../components/cardPresale";
import PresaleData from "../data/presale.json";

const Presale = () => {
  console.log(PresaleData);
  return (
    <>
      <Flex
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="initial"
        className="principalHome"
      >
        <ConnectWallet />
        <Center width="100%" color='white'>
          <img src={Logo} width="400px" alt="logo" />
        </Center>
        <SimpleGrid columns={[4, 4, 4, 8, 8, 8]} marginTop={[5, 5, 10, 10, 10, 10]} width="100%">
          {
            PresaleData.map((info, index) =>
              <GridItem colSpan={[4, 4, 2, 2, 2, 2]}>
                <CardPresale {...info} />
              </GridItem>
            )
          }
        </SimpleGrid>
      </Flex>
    </>

  );
}

export default Presale;
